import React from 'react'

const HeaderRight = () => {
  return (
    <div className="bg-black text-blue-300 pt-2">
    <h2>Email: thevendingfam@gmail.com</h2>
  </div>
)

}

export default HeaderRight
