
const HeaderLeft = () => {
  return (
    <div className="bg-black text-blue-300 pt-2">
      <h2>Call Us: (404)-383-0055</h2>
    </div>
  )
}

export default HeaderLeft
